import ProgressBar from 'react-bootstrap/ProgressBar';
import {FileText} from 'react-feather';
import {isBdc} from '../../../utils/isBdc';
import './InvestmentInProgressDetailCard.scss';
import {BdcProgressList} from './ProgressList';
import {RegDProgressList} from './ProgressList/RegDProgressList';

export const InvestmentInProgressDetailCard = ({investment, index, investmentsCount}) => {
  const commitment = isBdc(investment.investing_entity_type_id) ? investment.subscription_amount : investment.commitment_amount;

  return (
    <div className="investment-in-progress-detail-card">
      <div className="investment-in-progress-detail-card-header">
        {`${index + 1} of ${investmentsCount} active enrollment${investmentsCount > 1 ? 's' : ''}`}
        <div className="enrollment-status">
          <span className="badge-status">
            <FileText size={16} className="mr-1" /> Enrollment In Progress
          </span>
        </div>
      </div>
      <div className="investment-in-progress-detail-card-body">
        <h5>{investment.account_name || '*Discuss with Advisor'}</h5>
        <h5 className="commitment-amount">{`$${commitment.toLocaleString()}`}</h5>

      </div>
      <div className="investment-in-progress-detail-card-footer">
        <div className="progress-section">
          <span className="completion-text">{`${Math.round(investment.completion * 100)}% Complete`}</span>
          <ProgressBar now={investment.completion * 100} className="investment-in-progress-detail-card-progress-bar" />
          {
            isBdc(investment.investing_entity_type_id)
              ? <BdcProgressList inProgressBdcInvestment={investment} />
              : <RegDProgressList inProgressRegDInvestment={investment} />
          }
        </div>
      </div>
    </div>
  );
}