export const initialState = {
  showAlert: false,
  showConfetti: false,
  confettiComplete: false,
};

export const actions = {
  SHOW_ALERT: "SUBMIT_SUCCESS_ALERT",
  HIDE_ALERT: "HIDE_SUCCESS_ALERT",
  START_CONFETTI: "START_CONFETTI",
  STOP_CONFETTI: "STOP_CONFETTI",
  CONFETTI_COMPLETE: "CONFETTI_COMPLETE",
};


export const opportunitiesReducer = (state, action) => {
  switch (action.type) {
    case actions.SHOW_ALERT:
      return { ...state, showAlert: true, showConfetti: true };
    case actions.HIDE_ALERT:
      return { ...state, showAlert: false };
    case actions.SHOW_CONFETTI:
      return { ...state, showConfetti: true };
    case actions.HIDE_CONFETTI:
      return { ...state, showConfetti: false };
    case actions.CONFETTI_COMPLETE:
      return { ...state, confettiComplete: true, showConfetti: false };
    default:
      return state;
  }
};