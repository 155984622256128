import React, { useState, useMemo } from 'react';
import { createContext } from 'react';
import { useContext } from 'react';

export const OpportunitiesContext = createContext();

export const OpportunitiesProvider = (props) => {
  const [opportunities, setOpportunities] = useState([]);
  const [opportunityMap, setOpportunityMap] = useState({});
  const [opportunityEvents, setOpportunityEvents] = useState([]);
  const [custodians, setCustodians] = useState([]);

  const value = useMemo(() => ({
    opportunities,
    setOpportunities,
    opportunityMap,
    setOpportunityMap,
    opportunityEvents,
    setOpportunityEvents,
    custodians,
    setCustodians
  }), [opportunities, opportunityMap, setOpportunities, setOpportunityMap, opportunityEvents, setOpportunityEvents, custodians, setCustodians]);

  return (
    <OpportunitiesContext.Provider value={value}>
      {props.children}
    </OpportunitiesContext.Provider>
  );
}

export const useOpportunities = () => {
  const context = useContext(OpportunitiesContext);
  
  if (!context) {
    throw new Error('useOpportunities must be used within an OpportunitiesProvider');
  }
  return context;
};