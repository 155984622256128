import React, { useContext, useEffect, useMemo, useState } from "react";
import { Breadcrumb, Col, Container, Row, Button } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { useOpportunities } from "../../context/OpportunitiesContext";
import { logUserAction, UserAction } from "../../utils/logging.util";
import { formatDate } from "../../utils/date.util";
import { toCurrency } from "../../utils/number.util";
import { AccountsContext } from "../../context/AccountsContext";
import { ShowInterestModal } from "./ShowInterestModal";
import axios from "axios";
import { useInvestments } from "../../context/InvestmentsContext";
import "./OpportunityDetail.scss";
import { FALLBACK_TEXT } from "../../utils/constants";
import { ImportantDocuments } from "../../components/ImportantDocuments/ImportantDocuments";
import { EventCard } from "../../components/EventCard/EventCard";
import { CardStack } from "../../components/CardStack/CardStack";
import { UIContext } from "../../context/UIContext";
import { InvestmentInProgressDetailCard } from "./InvestmentInProgressDetailCard";
import { isBdc } from "../../utils/isBdc";

const filterAccounts = (accounts, interestList, bdcList, oppId) => {
  const ids = new Set([...interestList, ...bdcList].filter(i => i.investment_company_id === oppId).map(i => i.account_id));
  return accounts.filter(acc => !ids.has(acc.id));
};

export const OpportunityDetail = () => {
  const { userData } = useContext(UserContext);
  const { accounts } = useContext(AccountsContext);
  const  { setIsLoading } = useContext(UIContext);
  const { opportunityMap, opportunityEvents, custodians } = useOpportunities();
  const { interestList, setInterestList, bdcInterestList, setBdcInterestList, investments } = useInvestments();
  const { opportunityId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [availableAccounts, setAvailableAccounts] = useState([]);
  const opportunity = opportunityMap[+opportunityId];
  const relatedOpportunityEvents = opportunityEvents.filter((event) => event.investment_company_id === +opportunityId);
  const history = useHistory();
  const isBdcOpportunity = isBdc(opportunity?.investing_entity_type_id);

  const inProgressOpportunities = useMemo(() => {
    return [...interestList, ...bdcInterestList].filter(i => i.investment_company_id === +opportunityId);
  }, [interestList, bdcInterestList, opportunityId]);

  useEffect(() => {
    if (accounts.length) {
      setAvailableAccounts(filterAccounts(accounts, interestList, bdcInterestList, +opportunityId));
    }
  }, [accounts, interestList, bdcInterestList, opportunityId]);

  useEffect(() => {
    if (userData?.contact_id) logUserAction(userData.contact_id, UserAction.ViewOpportunityDetail, +opportunityId);
  }, [opportunityId, userData?.contact_id]);

  const submitInterest = async ({ accountId, investmentAmount }) => {
    setIsLoading(true);
    try {
      const priorInvestmentsInOpportunity = investments.investments.filter(i => i.investment_company_id === +opportunityId);
      const existingInvestment = priorInvestmentsInOpportunity.find(i => i.account_id === +accountId);
      const payload = {
        customerId: userData.customer_id,
        ...(accountId && { accountId }),
        commitment: investmentAmount,
        ...(existingInvestment && { parentId: existingInvestment.id })
      };
      const resp =  await axios.post(`/investments/investmentCompany/${opportunityId}/interest-list-item`, payload);
      await refetchLists();
      history.push({ pathname: '/opportunities', state: { showAlert: true } });
      return resp;
    } catch (err) {
      console.error("Error submitting interest", err);
    } finally {
      setIsLoading(false);
    }
  };

  const refetchLists = async () => {
    try {
      const [bdcInterests, interests] = await Promise.all([
        axios.get(`/bdc-investments/interests?customer_id=${userData.customer_id}`),
        axios.get(`/investments/interests?customer_id=${userData.customer_id}`)
      ]);
      setBdcInterestList(bdcInterests.data);
      setInterestList(interests.data);
    } catch (err) {
      console.error("Error refetching lists", err);
    }
  };

  return (
    <Container className="opportunity-detail-page">
      <Row>
        <Col>
        <Breadcrumb><Breadcrumb.Item><Link to="/opportunities">Opportunities</Link></Breadcrumb.Item>
        <Breadcrumb.Item active>{opportunity.investment_company_name}</Breadcrumb.Item></Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <div className="opportunity-header">
            <Row> 
              <Col md={1}> <img src={opportunity.mobile_logo_url} alt={opportunity.investment_company_name} className="opportunity-logo" />
              </Col>
              <Col md={11}><h1>{opportunity.investment_company_name}</h1>
              </Col>
            </Row>
            {opportunity.video_url ? (
              <div className="opportunity-video" style={{ marginBottom: "20px" }}>
                <video width="100%" height="350" controls poster={opportunity.video_url}>
                  <source src={opportunity.video_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <div style={{ marginBottom: "40px" }} />  
            )}
            <div className="investment-info">
              <div className="investment-text">
                <p><strong>Minimum Investment</strong></p>
                <p className="investment-value">{toCurrency(opportunity.minimum_commitment)}</p>
                <p><strong>{isBdcOpportunity ? "Subscription Deadline" : "Next Close"}</strong></p>
                <p className="investment-value">
                  {formatDate(isBdcOpportunity ? opportunity.subscription_deadline_date : opportunity.expected_close_date) || "TBD"}
                </p>
              </div>
              <Button className="invest-button" onClick={() => setShowModal(true)}>
                I'm interested in investing
              </Button>
            </div>
            <div className="about-investment">
              <h4>About Investment</h4>
              <p>{opportunity.description || FALLBACK_TEXT.NoDescription}</p>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="documents-card">
            <CardStack title="Important Documents">
              {opportunity.documents.length > 0 && (
                <ImportantDocuments documents={opportunity.documents} title = "" />
              )}
            </CardStack>
          </div>
          {inProgressOpportunities.length > 0 && (
                <>
                  <h5>Investments In Progress</h5>
                  <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                    {inProgressOpportunities.map((investment, index) => (
                      <InvestmentInProgressDetailCard
                        key={investment.id || index}
                        investment={investment} 
                        index={index} 
                        investmentsCount={inProgressOpportunities.length} />
                    ))}
                  </div>
                </>
              )
            }
          <Col md={10}>
          {relatedOpportunityEvents.length > 0 &&( 
            <CardStack title="Upcoming Webinar" className="mt-4">
                {relatedOpportunityEvents.map((e, i) => (
                  <EventCard event={e} key={i} />
                ))}
              </CardStack>
            )}
            </Col>
        </Col>
      </Row>
      <ShowInterestModal show={showModal} handleClose={() => setShowModal(false)} accounts={availableAccounts} custodiansList={custodians} opportunity={opportunity} submitInterest={submitInterest} />
    </Container>
  );
};