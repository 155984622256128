import { AccountType } from "../../utils/constants";

function isAccountEligible(
  account,
  investmentCompany,
  custodians,
) {
  // Non-custodian accounts are always eligible
  if (!account.custodian_id) return true;

  // Check entity-level eligibility
  if (!isEntityEligible(account, investmentCompany)) return false;

  // Check global custodian overrides
  return isGloballyEligible(account, custodians);
}

function isEntityEligible(account, investmentCompany) {
  return investmentCompany.custodians.some((custodian) =>
    custodian.id === account.custodian_id &&
    custodian.accountTypes?.some((type) => type.id === account.account_type_id)
  );
}

function isGloballyEligible(account, custodians){
  const custodian = custodians.find((c) => c.id === account.custodian_id);
  if (!custodian) return false;

  const isIRA = account.account_type_id === AccountType.IRA;

  if (isIRA && custodian.qualified === false) return false;
  if (!isIRA && custodian.non_qualified === false) return false;

  return true;
}

export { isAccountEligible };
