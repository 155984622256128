import {CheckCircle, RadioButtonUnchecked} from '@mui/icons-material';
import './ProgressListItem.scss';

export const ProgressListItem = (props) => {

  return (
    <div className="progress-list-item-wrapper">
      <div className="progress-list-item-label-wrapper">
        {props.completed ? 
          <CheckCircle sx={{color: 'var(--bip-capital-light-green)', marginRight: '0.25rem'}} /> 
          : <RadioButtonUnchecked sx={{marginRight: '0.25rem'}} />}
        <span>{props.label}</span>
      </div>
    </div>
  )
}