import {ProgressListItem} from "./ProgressListItem"

export const BdcProgressList = ({inProgressBdcInvestment}) => {
  return (
    <div>
      <ProgressListItem 
        label='Documents Received' 
        completed={!!inProgressBdcInvestment.docs_received_date}
      />
      <ProgressListItem 
        label='Monies Received' 
        completed={!!inProgressBdcInvestment.monies_received_date}
      />
      <ProgressListItem 
        label='Accreditation Status' 
        completed={!!inProgressBdcInvestment.accreditation_id}
      />
    </div>
  )
}