export const CashFlow = {
  CapitalCall: 1,
  Distribution: 2
}

export const CashFlowLabel = {
  [CashFlow.CapitalCall]: "Capital Call",
  [CashFlow.Distribution]: "Distribution"
}

export const DocumentType = {
  InvestorStatements: 1,
  K1: 2,
  Form1099: 3,
  SubscriptionAgreements: 4,
  GoverningDocuments: 5,
  Other: 6,
  PPM: 7,
  FormationDocuments: 8,
  EINSS4: 9,
  WiringInstructions: 10,
  W9: 11,
  PortfolioCompanyUpdates: 12,
  TrustDocuments: 13,
  LOA: 14,
  DistributionInstruction: 15,
  Identification: 16,
  TransferAgreements: 17,
  ShareholderDeck: 25,
}

export const Months = [
  'January', 
  'February', 
  'March', 
  'April', 
  'May', 
  'June', 
  'July', 
  'August', 
  'September', 
  'October', 
  'November', 
  'December'
]

export const DistributionInstructionType = {
  Wire: 1,
  Check: 2,
  ACH: 3,
  Brokerage: 4
}

export const WireType = {
  Domestic: 1,
  International: 2
}


export const FALLBACK_TEXT = {
  NoDescription: "No description",
  NoUpcomingEvents: "No upcoming events",
  NoK1s: "No K1s are available",
}

export const InvestingEntityType = {
  FundEquity: 1,
  FundDebt: 2,
  SPVEquity: 3,
  SPVDebt: 4,
  RealEstate: 5,
  BDCEquity: 6,
  BDCDebt: 7,
}

export const AccountType = {
  IRA:  2,
  Individual: 3,
  Joint: 4,
  Entity: 5,
}

export const Custodians  = {
  InspiraFinancial: 1,
  TDAmeritrade: 2,
  NA: 3,
  BIPCapital: 4,
}