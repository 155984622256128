import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { toCurrency } from "../../utils/number.util";
import "./ShowInterestModal.scss";
import { UIContext } from "../../context/UIContext";
import { createNotificationLog, logUserAction, UserAction } from "../../utils/logging.util";
import { UserContext } from "../../context/UserContext";
import { AccountType } from "../../utils/constants";
import { isAccountEligible } from "./account-eligibility";

export const ShowInterestModal = ({
  show,
  handleClose,
  accounts,
  custodiansList,
  opportunity: { id, investment_company_name, minimum_commitment, investing_entity_type_id, custodians },
  submitInterest,
}) => {
  const [selectedAccount, setSelectedAccount] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");
  const { errorMessage, isLoading } = useContext(UIContext);
  const { userData } = useContext(UserContext);

  const isDiscuss = selectedAccount === "discuss";
  const isFormValid =
    selectedAccount &&
    (isDiscuss || investmentAmount >= minimum_commitment);

  const handleInvestmentAmountChange = (e) => {
    const value = parseFloat(e.target.value.replace(/[^0-9.-]+/g, ""));
    setInvestmentAmount(isNaN(value) ? "" : value);
  };

  const handleFormSubmit = async () => {
    if (!isFormValid) return;

    const payload = {
      accountId: isDiscuss ? undefined : selectedAccount,
      investmentAmount: isDiscuss ? 0 : investmentAmount,
    };
    const resp = await submitInterest(payload);
    const metadata = {
      description: `${userData.first_name} ${userData.last_name} indicated interest to invest in ${investment_company_name}`
    };
    if (resp.data.investorId) {
      metadata.investorId = resp.data.investorId;
      const actionResp = await logUserAction(userData.contact_id, UserAction.IndicateInterestToInvest, +id, metadata);
      await createNotificationLog(actionResp.data.userActionLogId, userData.customer_id);
    } else if (resp.data.transactionId || resp.data.prospectId) {
      resp.data.transactionId ? metadata.transactionId = resp.data.transactionId : metadata.prospectId = resp.data.prospectId;
      const actionResp = await logUserAction(userData.contact_id, UserAction.IndicateInterestToInvestBdc, +id, metadata);
      await createNotificationLog(actionResp.data.userActionLogId, userData.customer_id);
    }
    handleClose();
  };

  useEffect(() => {
    if (isDiscuss) setInvestmentAmount("");
  }, [isDiscuss]);
  
  return (
    <Modal show={show} onHide={handleClose} centered className="show-interest-modal">
      <Modal.Header closeButton>
        <Modal.Title>{investment_company_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="selectAccount">
            <Form.Label>Select Account</Form.Label>
            <div className="bip-capital-select-wrapper">
              <Form.Control
                as="select"
                custom
                value={selectedAccount}
                onChange={(e) => setSelectedAccount(e.target.value)}
                disabled={isLoading}
              >
            {accounts.length > 0 ? (<>
            <option value="" disabled>Select Account</option>
            {accounts.map(({ id, account_name, custodian_id, account_type_id }) => {
              const account = { id, custodian_id, account_type_id };
              const isAccountDisabled = !isAccountEligible(account, {custodians}, custodiansList);
              
              return (
                <option key={id} value={id} disabled={isAccountDisabled}>
                  {account_name}
                </option>
              );
            })} 
                </> 
                ) : (
                <option value="" disabled>No available accounts.</option>
                )}
                <option value="discuss">⭐ Discuss with Advisor</option>
              </Form.Control>
            </div>
          </Form.Group>

          {selectedAccount !== "discuss" && selectedAccount !== "" && (
          <Form.Group controlId="desiredInvestment" className="mt-3">
            <Form.Label>Desired Investment</Form.Label>
            <Form.Control
              type="text"
              placeholder={`Enter at least ${toCurrency(minimum_commitment)}`}
              value={investmentAmount ? toCurrency(investmentAmount) : ""}
              onChange={handleInvestmentAmountChange}
              isInvalid={investmentAmount && investmentAmount < minimum_commitment}
              aria-invalid={investmentAmount && investmentAmount < minimum_commitment}
              disabled={isLoading}
              autoComplete="off"
            />
            <Form.Text className="text-muted">
              Minimum Investment: {toCurrency(minimum_commitment)}
            </Form.Text>
            {investmentAmount && investmentAmount < minimum_commitment && (
              <Alert variant="danger" className="mt-2">
                Investment must be at least {toCurrency(minimum_commitment)}.
              </Alert>
            )}
          </Form.Group>
          )}
          {errorMessage && (
            <Alert variant="danger" className="mt-3">
              {errorMessage}
            </Alert>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="indicate-interest-btn"
          onClick={handleFormSubmit}
          disabled={!isFormValid || isLoading || (accounts.length === 0 && !isDiscuss)}
        >
          {isLoading ? "Submitting..." : "Indicate Interest"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};