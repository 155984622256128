import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({ currentPage, totalPages, pageLimit = 7, onPageChange, className }) => {
  const renderPaginationItems = () => {
    let items = [];
    let startPage = Math.max(1, currentPage - Math.floor(pageLimit / 2));
    let endPage = Math.min(totalPages, startPage + pageLimit - 1);

    if (startPage > 1) {
      items.push(<Pagination.First key="first" onClick={() => onPageChange(1)} />);
      items.push(<Pagination.Prev key="prev" onClick={() => onPageChange(currentPage - 1)} />);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(<Pagination.Next key="next" onClick={() => onPageChange(currentPage + 1)} />);
      items.push(<Pagination.Last key="last" onClick={() => onPageChange(totalPages)} />);
    }

    return items;
  };

  return <Pagination className={className}>{renderPaginationItems()}</Pagination>;
};

export default PaginationComponent;
