import "./OpportunitiesPage.scss";
import React, { useContext, useEffect, useState, useReducer, useCallback , useMemo, useRef} from "react";
import { Container, Row, Col, Pagination, Alert } from "react-bootstrap";
import { useOpportunities } from "../../context/OpportunitiesContext";
import { UserContext } from "../../context/UserContext";
import { logUserAction, UserAction } from "../../utils/logging.util";
import { Link, useLocation, useHistory } from "react-router-dom";
import { toCurrency} from "../../utils/number.util";
import { formatDate } from "../../utils/date.util";
import Confetti from "react-confetti";
import PaginationComponent from "../../components/Pagination/PaginationComponent";
import { getDistinctItemsById } from "../../utils/array.util";
import { opportunitiesReducer, initialState, actions } from "../../reducers/opportunitiesReducer";
import { FALLBACK_TEXT } from "../../utils/constants";
import { useInvestments } from "../../context/InvestmentsContext";
import { AccountsContext } from "../../context/AccountsContext";
import { isBdc } from "../../utils/isBdc";

export const OpportunitiesPage = () => {
  const { opportunities, opportunityEvents } = useOpportunities();
  const { userData } = useContext(UserContext);
  const { accounts } = useContext(AccountsContext);
  const { interestList, bdcInterestList } = useInvestments();
  const [currentPage, setCurrentPage] = useState(1);
  const [state, dispatch] = useReducer(opportunitiesReducer, initialState);
  const itemsPerPage = 5;
  const location = useLocation();
  const history = useHistory();
  const { showAlert } = location.state || {};
  
  useEffect(() => {
    logUserAction(userData.contact_id, UserAction.ViewOpportunitiesPage);
    if (showAlert) showAlertAndConfetti();
  }, [userData, showAlert]);
  
  const showAlertAndConfetti = useCallback(() => {
    dispatch({ type: actions.SHOW_ALERT });
    history.replace({ pathname: location.pathname, state: {} });
  }, [dispatch, history, location.pathname]);

  const handleConfettiComplete = useCallback(() => {
    dispatch({ type: actions.CONFETTI_COMPLETE });
    setTimeout(() => {
      dispatch({ type: actions.HIDE_ALERT });
    }, 2000);
  }, [dispatch]);
    
  const handlePageChange = useCallback((pageNumber) => setCurrentPage(pageNumber), []);
  
  const visibleOpportunities = opportunities.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = useMemo(() => Math.ceil(opportunities.length / itemsPerPage), [opportunities]);
  const distinctEvents = getDistinctItemsById(opportunityEvents);

  return (
    <Container className="bip-capital-opportunities-page">
      {state.showConfetti && !state.confettiComplete && (
        <Confetti gravity={0.2} recycle={false} numberOfPieces={500} onConfettiComplete={handleConfettiComplete} />
      )}
      {state.showAlert && <Alert variant="success" className="top-right-alert">Investment submitted successfully!</Alert>}
      <h1 className="opportunity-header">Opportunities</h1>
      <div className="opportunities-card-wrapper">
        <Row>
          <Col lg={7}>
            {visibleOpportunities.length === 0 && <p>No opportunities available.</p>}
            {visibleOpportunities.map(({ id, mobile_logo_url, investment_company_name, minimum_commitment, expected_close_date, description, investing_entity_type_id, subscription_deadline_date }) => (
              <Link key={id} to={`/opportunities/${id}`} className="opportunity-card-link">
                <div className="opportunity-card">
                  <Row className="align-items-center">
                    <Col md={3} className="d-flex justify-content-center">
                      <img src={mobile_logo_url} alt={investment_company_name} className="opportunity-image" />
                    </Col>
                    <Col md={9}>
                      <div className="card-body">
                        <h5 className="opportunity-title">{investment_company_name}</h5>
                        <p className="opportunity-details">
                          <i className="fas fa-money-bill-alt"></i> <strong>Investment Min:</strong> {toCurrency(minimum_commitment)} {" | "}
                          {isBdc(investing_entity_type_id) ? (
                            <> <i className="fas fa-calendar-alt"></i> <strong>Subscription Deadline:</strong> {formatDate(subscription_deadline_date) || "TBD"}
                            </>
                          ) : (
                            <> <i className="fas fa-calendar-alt"></i> <strong>Date of Close:</strong> {expected_close_date ? formatDate(expected_close_date) : "TBD"}
                            </>
                          )}
                        </p>
                        <p className="opportunity-description text-truncate">{description || FALLBACK_TEXT.NoDescription}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Link>
            ))}
            {totalPages > 1 && (<>
              <PaginationComponent className="pagination-container" currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
              <p className="pagination-text">
                Showing {Math.min((currentPage - 1) * itemsPerPage + 1, opportunities.length)} to {Math.min(currentPage * itemsPerPage, opportunities.length)} of {opportunities.length} entries
              </p>
            </>)}
          </Col>
          <Col lg={5}>
            <div className="bip-webinar-section">
              <h5 className="webinar-title">Upcoming Webinars</h5>
              {distinctEvents.length > 0 ? (
                distinctEvents.map(({ id, logo_url, name, start_time }, index) => (
                  <div key={index} className="webinar-card">
                    <Row>
                      <Col md={3}>
                        <img src={logo_url} alt={name} className="webinar-image" />
                      </Col>
                      <Col md={9}>
                        <h5 className="webinar-link-title">
                          <Link to={`/events/${id}`} className="webinar-name-link">
                            {name}
                          </Link>
                        </h5>
                        <p className="webinar-time">
                          <i className="fas fa-calendar-alt"></i> {formatDate(start_time)}
                        </p>
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <p>{FALLBACK_TEXT.NoUpcomingEvents}</p>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};